import React from 'react'

import './scss/RSVP.scss'

import {festivalLink} from '../global'

const RSVP = () => (
    <div className="rsvp-container">
        <a aria-label="See the Festival Archive" href={festivalLink}>See the Festival Archive</a>
    </div>
)

export default RSVP