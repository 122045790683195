import React, { Component } from 'react';

import "./scss/Header.scss"

import logo from '../img/logo.png'

import Menu from './Menu'

class Header extends Component {

    constructor() {
        super();
        this.state = {
            menuIsOpen: false
        }
    }

    handleMenuClick = (e) => {
        this.setState({ menuIsOpen: !this.state.menuIsOpen });
        e.preventDefault()
    }

    handleLinkClick = () => {
        this.setState({ menuIsOpen: false })
    }

    render = () => (
        <div className="header-container">
            <header>
                <h1>
                    <a href="https://eyebeam.org">
                        <img src={logo} alt="Eyebeam Logo" />
                    </a>
                </h1>
                <span><b>From the Rupture:</b>&nbsp;February 17–20</span>
                <a href="#menu" className={this.state.menuIsOpen ? 'focus' : ''} onClick={this.handleMenuClick} aria-label="Open Navigation Menu">Menu</a>
            </header>
            <Menu handleLinkClick={this.handleLinkClick} className={this.state.menuIsOpen ? 'open' : ''} />
        </div>
    )
}



export default Header;