import React from 'react';

import './scss/Footer.scss'

const Footer = props => (
    <footer className="footer-container">

    </footer>
)

export default Footer;