import React from 'react';

import Events from "../components/Events"

import "./scss/Schedule.scss"

const Schedule = props => (
    <section className="schedule-container" id="schedule">
        <hgroup>
            <h2>
                From the Rupture
        </h2>
            <h3 className="subtitle">
                Ideas & Actions for the Future
        </h3>
        </hgroup>
        <Events />
    </section>
)

export default Schedule