import React, {Component} from 'react'

import "./scss/Home.scss"

import RSVP from '../components/RSVP.jsx'
import Cursor from '../components/Cursor.jsx'
import Header from '../components/Header'
import Hero from '../components/Hero'
import Footer from '../components/Footer'
import Schedule from './Schedule'
import About from './About'

class Home extends Component {
    constructor() {
        super()

        this.state = {
            lightMode: false,
            effects: false,
            cursorPosition: {
                x: 0,
                y: 0
            }
        }

        this.handleMouseMove = this.handleMouseMove.bind(this)
    }

    handleMouseMove(e) {
        if (this.state.effects) {
            this.setState({
                cursorPosition: {x: e.pageX, y: e.pageY}
            })
        }

    }

    render() {
        return (
            <div className={this.state.lightMode ? 'container light-mode' : 'container'} onMouseMove={this.handleMouseMove}>
                <RSVP />
                <Cursor effects={this.state.effects} position={this.state.cursorPosition} />
                <Header />
                <Hero />
                <main role="main">
                    <Schedule />
                    <About />
                </main>
                <Footer />
            </div>
        )
    }
}

export default Home