import React from 'react'

import "./scss/Event.scss"

const Host = ({host}) => <p>Hosted by <span>{host}</span></p>

const Event = ({event}) => {
    const {title, subtitle, date, artists, talks, conversations, host} = event
    const eventDate = new Date(date)
    const weekdays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

    return (
        <div className="event-container">
            <div className="event-top">
                <div className="event-date-wrapper">
                    <div className="event-month">
                        {months[eventDate.getMonth()]}
                    </div>
                    <div className="event-date">
                        {eventDate.getUTCDate()}
                    </div>
                    <div className="event-day">
                        {weekdays[eventDate.getDay()]}
                    </div>
                </div>
                <div className="event-title">
                    {title}
                    <div className="event-subtitle">
                        {subtitle}
                    </div>
                </div>
            </div>
            <div className="event-artists">
                <h3>
                    Launching new works by:
                </h3>
                <span className="artists">
                    <a href={artists[0].link}>{artists[0].name}</a> & <a href={artists[1].link}>{artists[1].name}</a>
                </span>
            </div>
            <div className="event-talks">
                <p>Talks by <span>{talks.sort().join(', ')}</span></p>

            </div>
            <div className="event-conversations">
                <p>Conversations with <span>{conversations.sort().join(', ')}</span></p>
            </div>
            <div className="event-host">
                {host && <Host host={host} />}
            </div>
        </div>
    )
}

export default Event