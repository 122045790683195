import React from 'react';

import './scss/Hero.scss'

// import grid from "../img/grid_caved.png";
import glyph from "../img/glyph_rotated.png";
import grid from "../img/orbs_cave_white.jpg";

const Hero = props => (
    <div className="hero-container" aria-label="White grid graphic with 8 shiny spheries bending down into the grid">
        <div className="grid">
            <img src={grid} alt="White wavy grid" />
            <div className="glyph">
                <img src={glyph} alt="Eyebeam logo square" />
            </div>
        </div>
    </div>
)

export default Hero;