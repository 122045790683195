import React from 'react';

import './scss/Events.scss'

import Event from "./Event"
import { v4 as uuidv4 } from 'uuid';
import { events } from "../global"

const Events = props => {
    return (
<div className="events-container">
        {events.map(event => <Event event={event} key={uuidv4()} />)}
    </div>
    )
}

export default Events