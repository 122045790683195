import React from 'react'

import AnchorLink from "react-anchor-link-smooth-scroll"
import {festivalLink} from '../global'

import "./scss/Menu.scss"

const Menu = props => (
    <nav aria-label="Main">
        <ul className={props.className} aria-hidden="true">
            <li><a
                href={festivalLink}
                tabIndex={props.className !== 'open' ? '-1' : ''}>
                See the Festival Archive
                </a>
            </li>
            <li><AnchorLink
                onClick={props.handleLinkClick}
                offset="100" href="#schedule"
                tabIndex={props.className !== 'open' ? '-1' : ''}>
                Schedule
                    </AnchorLink>
            </li>
            <li><AnchorLink
                onClick={props.handleLinkClick}
                offset="100"
                href="#about"
                tabIndex={props.className !== 'open' ? '-1' : ''}>
                About
                </AnchorLink>
            </li>
        </ul>
    </nav>
)

export default Menu