const rsvpLink = "https://www.eventbrite.com/e/from-the-rupture-ideas-and-actions-for-the-future-tickets-138328868369"
const festivalLink = "https://fromtherupture.eyebeam.org"

const events = [
    {
        title: "Platforms For The People",
        subtitle: "Centering co-creation, consent, and mental health in new technology",
        date: Date.parse('2021-02-17'),
        artists: [
            {
                name: "Rashaad Newsome",
                link: "https://rashaadnewsome.com/"
            },
            {
                name: "Xin Xin",
                link: "https://xin-xin.info/info/"
            }
        ],
        talks: [
            "Joan Greenbaum",
            "Chancey Fleet",
            "Cydney Brown",
            "Mutale Nkonde"
        ],
        conversations: [
            "Dorothy Santos & Xin Xin",
            "Legacy Russell & Rashaad Newsome"
        ],
        host: "Salome Asega"
    },
    {
        title: "Planetary Exchange Networks",
        subtitle: "Decentralized knowledge and natural intelligence",
        date: Date.parse('2021-02-18'),
        artists: [
            {
                name: "Juan Pablo García Sossa",
                link: "https://puntojpgs.com/"
            },
            {
                name: "Solar Protocol",
                link: "http://solarprotocol.net/"
            }
        ],
        talks: [
            "Timothy Morton",
            "Miguel López",
            "Elaine Díaz",
        ],
        conversations: [
            "Nisa Mackie & Solar Protocol",
            "Juan Pablo García Sossa & Julie Ricard"
        ],
        host: "Jerron Herman"
    },
    {
        title: "Digital Intimacies",
        subtitle: "Reimagining performance and modes of connection",
        date: Date.parse('2021-02-19'),
        artists: [
            {
                name: 'Apian',
                link: "http://www.apian.ch/"
            },
            {
                name: "Volumetric Performance Toolbox",
                link: "https://volumetricperformance.com/"
            }
        ],
        talks: [
            "Aza Raskin",
            "Kara Gilmour",
            "LaJuné McMillian"
        ],
        conversations: [
            "Aladin Borioli & Paige Mullhearn",
            "Amelia Winger Bearskin & Valencia James"
        ],
        host: "Lauren Ruffin"
    },
    {
        title: "State Sanctioned Surveillance and Creative Opposition",
        subtitle: "Community organizing in digital space",
        date: Date.parse('2021-02-20'),
        artists: [
            {
                name: "Dillon Sung",
                link: "https://www.eyebeam.org/residents/stop-lapd-spying-coalition/"
            },
            {
                name: "Veil Machine in Collaboration with Kink Out",
                link: "https://veilmachine.com/"
            }

        ],
        talks: [
            "Sasha Costanza-Chock",
            "Juno Mac",
            "Laurie Jo Reynolds",
            "Vincent Southerland",
        ],
        conversations: [
            "Nash Sheard & Veil Machine",
            "Dillon Sung & Vincent Southerland",
        ],
        host: "Nash Sheard",
    },

]

module.exports = {rsvpLink, festivalLink, events}

